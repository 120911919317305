import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Link,
  Flex,
  List,
  ListItem,
  useColorModeValue,
  Stack,
  Image,
} from "@chakra-ui/react";

const PhotographerLandingPage: React.FC = () => {
  return (
    <Flex minH="50vh" direction={{ base: "column", lg: "row" }}>
      {/* Left Section */}
      <Box flex="1" p={12}>
        <Heading
          as="h1"
          fontSize={{ base: "3xl", md: "7xl" }}
          fontWeight="black"
          mb={12}
          lineHeight="1"
        >
          how it works
        </Heading>

        <List spacing={8} mb={12}>
          <ListItem display="flex" alignItems="center">
            <Image
              src="/bullet.png"
              alt="Swelter Logo"
              width="32px"
              height="32px"
              mr={4}
            />
            <Text fontSize="xl" fontStyle="italic">
              CAPTURE EPIC SURF MOMENTS
            </Text>
          </ListItem>
          <ListItem display="flex" alignItems="center">
            <Image
              src="/bullet.png"
              alt="Swelter Logo"
              width="32px"
              height="32px"
              mr={4}
            />
            <Text fontSize="xl" fontStyle="italic">
              UPLOAD YOUR CONTENT TO SWELTER
            </Text>
          </ListItem>
          <ListItem display="flex" alignItems="center">
            <Image
              src="/bullet.png"
              alt="Swelter Logo"
              width="32px"
              height="32px"
              mr={4}
            />
            <Text fontSize="xl" fontStyle="italic">
              SURFERS FIND AND PURCHASE YOUR WORK
            </Text>
          </ListItem>
          <ListItem display="flex" alignItems="center">
            <Image
              src="/bullet.png"
              alt="Swelter Logo"
              width="32px"
              height="32px"
              mr={4}
            />
            <Text fontSize="xl" fontStyle="italic">
              GET PAID FOR YOUR TALENT
            </Text>
          </ListItem>
        </List>

        <Stack spacing={4} maxW="300px">
          <Button
            as={Link}
            href="/signup"
            variant="outline"
            borderWidth="1px"
            borderColor="black"
            borderRadius="full"
            size="lg"
            px={8}
            _hover={{
              bg: "blackAlpha.50",
            }}
          >
            apply
          </Button>
          <Button
            as={Link}
            href="/login"
            variant="outline"
            borderWidth="1px"
            borderColor="black"
            borderRadius="full"
            size="lg"
            px={8}
            _hover={{
              bg: "blackAlpha.50",
            }}
          >
            login
          </Button>
        </Stack>

        <Box mt={12}>
          <Heading
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="black"
            mb={2}
          >
            free monthly membership
          </Heading>
          <Text fontSize="xl" fontStyle="italic">
            25% platform fee per sale
          </Text>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        flex="1"
        position="relative"
        overflow="hidden"
        transition="all 0.5s ease"
        _hover={{
          "& > img": {
            transform: "scale(1.05)",
          },
          "& > div": {
            bg: "rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Image
          src="/surfer-5.avif"
          alt="Surfer in a wave"
          objectFit="cover"
          w="100%"
          h="100%"
          transition="transform 0.5s ease"
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="transparent"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          p={12}
          transition="all 0.3s ease"
        >
          <Heading
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="black"
            mb={4}
            lineHeight="1"
            color="white"
            textShadow="2px 2px 4px rgba(0,0,0,0.5)"
          >
            become a
            <br />
            photographer
          </Heading>
          <Button
            as={Link}
            href="/signup"
            variant="outline"
            borderWidth="2px"
            borderColor="white"
            color="white"
            borderRadius="full"
            size="lg"
            px={8}
            _hover={{
              bg: "whiteAlpha.200",
              transform: "translateY(-4px)",
            }}
            transition="all 0.3s ease"
          >
            apply
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default PhotographerLandingPage;
