// SurferLandingPage.tsx
import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Spacer,
  SimpleGrid,
  useBreakpointValue,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";
import MediaSearchFilter, {
  FilterValues,
} from "../../components/MediaSearchFilter";
import { useNavigate } from "react-router-dom";
import { searchMedia } from "../../services/media";
import { Media } from "../../types/media";
import { format, subDays } from "date-fns";

const SurferLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const [recentMedia, setRecentMedia] = useState<Media[]>([]);
  const [loading, setLoading] = useState(true);

  const isInView = useInView(gridRef, {
    once: true,
    margin: "-100px",
  });

  // Determine number of images based on screen size
  const columns = useBreakpointValue({ base: 2, md: 3 }) ?? 2;

  useEffect(() => {
    const fetchRecentMedia = async () => {
      try {
        const endDate = new Date();
        const startDate = subDays(endDate, 7); // Get media from last 7 days

        const searchParams = {
          startDateTime: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
          endDateTime: format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
          surfBreakId: "", // Empty string to get media from all surf breaks
        };

        const media = await searchMedia(searchParams);
        setRecentMedia(media.slice(0, 9));
      } catch (error) {
        console.error("Error fetching recent media:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentMedia();
  }, [columns]);

  const handleFilter = (filters: FilterValues) => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });
    navigate(`/media?${searchParams.toString()}`);
  };

  return (
    <VStack
      spacing={16}
      align="center"
      width="100%"
      pt={{ base: "20px", md: "10vh" }}
    >
      <Box width="100%" maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Heading
            as="h1"
            fontSize={{ base: "2xl", md: "6xl" }}
            fontWeight="black"
            letterSpacing="-0.05em"
            color="black"
          >
            find your surf photos and videos
          </Heading>
          <MediaSearchFilter onFilter={handleFilter} />

          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="black"
            color="black"
            alignSelf="flex-start"
            mt={12}
            mb={6}
            letterSpacing="-0.05em"
          >
            recent uploads
          </Heading>

          {/* Demo Media Grid */}
          <Box position="relative" mt={4} ref={gridRef}>
            {loading ? (
              <Center py={16}>
                <Spinner size="xl" color="accent.500" />
              </Center>
            ) : (
              <SimpleGrid columns={[3, 3, 3]} spacing={1} width="100%">
                {recentMedia.map((media, index) => (
                  <motion.div
                    key={media.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{
                      opacity: isInView ? 1 : 0,
                      y: isInView ? 0 : 20,
                    }}
                    transition={{
                      duration: 0.6,
                      delay: index * 0.1,
                      ease: "easeOut",
                    }}
                    style={{ aspectRatio: "1", width: "100%" }}
                  >
                    <Box
                      // as="a"
                      // href={media.album ? `/albums/${media.album}` : "#"}
                      position="relative"
                      width="100%"
                      height="100%"
                      overflow="hidden"
                      borderRadius="0"
                      boxShadow="none"
                      transition="all 0.3s ease"
                      display="block"
                      _hover={{
                        transform: "translateY(-2px)",
                        "& > div": {
                          transform: "scale(1.05)",
                        },
                      }}
                    >
                      <Box
                        as="img"
                        src={
                          media.media_type === "photo"
                            ? media.watermarked_file
                            : media.thumbnail
                        }
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        objectFit="cover"
                        transition="transform 0.3s ease"
                      />
                    </Box>
                  </motion.div>
                ))}
              </SimpleGrid>
            )}
          </Box>
        </VStack>
      </Box>
    </VStack>
  );
};

export default SurferLandingPage;
