import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  useToast,
  FormErrorMessage,
  Textarea,
  Progress,
  HStack,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { signupUser } from "../../services/auth";
import { useNavigate } from "react-router-dom";

interface SignupFormInputs {
  email: string;
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  bio: string;
  location: string;
  country: string;
}

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  username: yup
    .string()
    .required("Username is required")
    .min(3, "Username must be at least 3 characters"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  bio: yup.string().default("").max(500, "Bio must not exceed 500 characters"),
  location: yup.string().default(""),
  country: yup.string().required("Country is required for bank account setup"),
});

const SignupPage: React.FC = () => {
  const [step, setStep] = React.useState(1);
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SignupFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      bio: "",
      location: "",
      country: "US",
      email: "",
      username: "",
      password: "",
      first_name: "",
      last_name: "",
    },
    mode: "onChange",
  });

  const totalSteps = 3;

  const onSubmit = async (data: SignupFormInputs) => {
    try {
      const signupData = {
        ...data,
        is_photographer: true,
        is_surfer: false,
      };
      await signupUser(signupData);
      toast({
        title: "Signup successful.",
        description:
          "A verification email has been sent to your email address.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/please-verify-email");
    } catch (error: any) {
      let errorMessage = "Something went wrong.";
      if (error.response?.data) {
        const errorData = error.response.data;
        if (typeof errorData === "string") {
          errorMessage = errorData;
        } else if (typeof errorData === "object") {
          errorMessage = Object.entries(errorData)
            .map(([key, value]) => {
              if (Array.isArray(value)) {
                return `${key}: ${value.join(" ")}`;
              } else if (typeof value === "string") {
                return `${key}: ${value}`;
              }
              return "";
            })
            .filter(Boolean)
            .join(" ");
        }
      }

      toast({
        title: "Signup failed.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const nextStep = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation(); // Add this to prevent event bubbling
    let fieldsToValidate: (keyof SignupFormInputs)[] = [];

    switch (step) {
      case 1:
        fieldsToValidate = ["email", "username", "password"];
        break;
      case 2:
        fieldsToValidate = ["first_name", "last_name"];
        break;
    }

    const isStepValid = await trigger(fieldsToValidate);
    if (isStepValid) {
      setStep((prev) => Math.min(prev + 1, totalSteps));
    }
  };

  const prevStep = () => {
    setStep((prev) => Math.max(prev - 1, 1));
  };

  const watchedValues = watch();

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div key="step1">
            <FormControl id="email" mb={4} isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                {...register("email")}
                data-form-type="email"
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="username" mb={4} isInvalid={!!errors.username}>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                {...register("username")}
                data-form-type="username"
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="password" mb={4} isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                {...register("password")}
                data-form-type="new-password"
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </div>
        );
      case 2:
        return (
          <div key="step2">
            <FormControl id="first_name" mb={4} isInvalid={!!errors.first_name}>
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                {...register("first_name")}
                data-form-type="given-name"
              />
              <FormErrorMessage>{errors.first_name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="last_name" mb={4} isInvalid={!!errors.last_name}>
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                {...register("last_name")}
                data-form-type="family-name"
              />
              <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
            </FormControl>
          </div>
        );
      case 3:
        return (
          <div key="step3">
            <FormControl id="country" mb={4} isInvalid={!!errors.country}>
              <FormLabel>
                What country are you based in? This is used to set up your bank
                account for payouts.
              </FormLabel>
              <Select {...register("country")} placeholder="Select country">
                <option value="US">United States</option>
                <option value="AU">Australia</option>
              </Select>
              <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="location" mb={4} isInvalid={!!errors.location}>
              <FormLabel>Location</FormLabel>
              <Input
                type="text"
                {...register("location")}
                placeholder="Where are you based?"
                data-form-type="off"
              />
              <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="bio" mb={4} isInvalid={!!errors.bio}>
              <FormLabel>Bio</FormLabel>
              <Textarea
                {...register("bio")}
                placeholder="Tell us about yourself..."
                data-form-type="off"
              />
              <FormErrorMessage>{errors.bio?.message}</FormErrorMessage>
            </FormControl>
          </div>
        );
    }
  };

  return (
    <Flex minH="80vh" align="center" justify="center">
      <Box
        bg="white"
        p={8}
        rounded="lg"
        shadow="lg"
        borderWidth="2px"
        borderColor="black"
        w={{ base: "90%", md: "500px" }}
      >
        <Heading mb={6} textAlign="center">
          apply to be a photographer
        </Heading>

        <Progress value={(step / totalSteps) * 100} mb={8} />

        <form onSubmit={handleSubmit(onSubmit)}>
          {renderStep()}

          <HStack spacing={4} mt={8}>
            {step > 1 && (
              <Button
                width="full"
                onClick={prevStep}
                variant="outline"
                type="button"
              >
                Previous
              </Button>
            )}

            {step < totalSteps ? (
              <Button
                width="full"
                onClick={nextStep}
                onSubmit={(e) => e.preventDefault()}
                type="button"
              >
                Next
              </Button>
            ) : (
              <Button
                width="full"
                type="submit"
                isLoading={isSubmitting}
                colorScheme="blue"
              >
                Submit
              </Button>
            )}
          </HStack>
        </form>

        <Text mt={4} textAlign="center">
          Already have an account?{" "}
          <Button variant="link" onClick={() => navigate("/login")}>
            Login
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default SignupPage;
